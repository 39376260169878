import { AXIOS } from "@/AXIOS/http-common";

const state = {
  listOfOrganizationsForSearch: [],
  listAllTechProcessesByUnitId: [],
  transferOrganizationList: [],
  sentForStorageOrganizationList: [],
  dataEntryMainPage: null,
  selectedUnitId: null,
};

const urlPrefix = "data-entry";

const actions = {
  setSelectedUnitId(context, data) {
    context.commit("setSelectedUnitId", data);
  },

  async getListOfOrganizationsForSearch(context) {
    return await AXIOS.get(urlPrefix + "/list/organizations").then(
      (response) => {
        console.log(response.data);
        context.commit("setListOfOrganizationsForSearch", response.data);
      }
    );
  },

  async getTransferOrganizationList(context, data) {
    return await AXIOS.get(
      urlPrefix +
        "/get/list/for/transfer?organizationWasteId=" +
        data.organizationWasteId +
        "&unitId=" +
        data.unitId
    ).then((response) => {
      context.commit("setTransferOrganizationList", response.data);
    });
  },

  async getSentForStorageOrganizationList(context, data) {
    return await AXIOS.get(
      urlPrefix +
        "/get/list/for/storage/sent?organizationWasteId=" +
        data.organizationWasteId +
        "&unitId=" +
        data.unitId
    ).then((response) => {
      context.commit("setSentForStorageOrganizationList", response.data);
    });
  },

  async getDataStored(context, data) {
    return await AXIOS.get(
      urlPrefix + "/get/data/entry/storedata?data=" + data.date +
      "&techProcessHasWasteId=" +
      data.techProcessHasWasteId
    );
  },

  async getMainPageForDataEntry(context, id) {
    return await AXIOS.get(
      urlPrefix + "/get/data/entry/page?techProcessHasWasteId=" + id
    ).then((response) => {
      context.commit("setDataEntryMainPage", response.data);
    });
  },

  async getListAllTechProcessesByUnitId(context, data) {
    return await AXIOS.get(
      urlPrefix +
        "/get/processes?unitId=" +
        data.unitId +
        "&page=" +
        data.page +
        "&size=" +
        data.size
    ).then((response) => {
      console.log(response.data);
      context.commit("setListAllTechProcessesByUnitId", response.data);
    });
  },

  async receiveDataEntryForm(context, data) {
    console.log(context);
    let number = {num:0, fileType:".pdf"};
    let resp;
    await AXIOS.post(
      urlPrefix +
        "/receive/form?techProcessHasWasteId=" +
        data.id +
        "&actionId=" +
        data.actionId,
      data.modalData
    ).then((response) => {
      number.num=response.data;
      resp=response;
    });
    if (number.num!=0 && Number.isInteger(number.num))
    await AXIOS({
      url: "report/get/passport", //your url
      data: number,
      method: "POST",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Сопроводительный паспорт" + number.fileType); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
    else
    return resp
  },

  async receiveDataEntryFormWasteDecline(context,data) {
    console.log(context);
    let number = {num:0};
     await AXIOS.post(
      urlPrefix +"/receive/formwaste",
      data
    ).then((response) => {
      number.num=response.data;
    });
    if (number.num!=0)
    {
      number.fileType=data.data[0].passport.fileType;
      await AXIOS({
        url: "report/get/passport", //your url
        data: number,
        method: "POST",
        responseType: "blob", // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Сопроводительный паспорт" + number.fileType); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    }
  },

  async GetNumberPassport() {
     return await AXIOS.get(
      "report/get/NumberPass"
    ).then((response) => {
      return response.data;
    });
  },

  async checkStoreValidityDate() {
    return await AXIOS.get(urlPrefix + "/check/validity/date");
  },
  async checkReg() {
    return await AXIOS.get(urlPrefix + "/check/validity/reg");
  },
  async checkStoreValidityDatehran() {
    return await AXIOS.get(urlPrefix + "/check/validity/datehran");
  },
  async checkStoreValidityDatezahran() {
    return await AXIOS.get(urlPrefix + "/check/validity/datezahran");
  },
  async checkStoreValidityDatehranOnliSum(context, data) {
    console.log("checkStoreValidityDatehranOnliSum");
    console.log(context);
    console.log(data);
    return await AXIOS.get(urlPrefix + "/check/validity/datehranonlisum?OrgWasteId="+data.wasteId+"&ProcessId="+data.processId);
  },
  async checkStoreValidityDatezahranOnliSum(context, data) {
    console.log("checkStoreValidityDatezahranOnliSum");
    console.log(context);
    console.log(data);
    return await AXIOS.get(urlPrefix + "/check/validity/datezahranonlisum?OrgWasteId="+data.wasteId+"&ProcessId="+data.processId);
  },
  async check1LotsBid() {
    return await AXIOS.get("Lots/chek1");
  },
  async check2LotsBid() {
    return await AXIOS.get("Lots/chek2");
  },
  
};

const mutations = {
  setListOfOrganizationsForSearch(state, data) {
    state.listOfOrganizationsForSearch = data;
  },

  setListAllTechProcessesByUnitId(state, data) {
    state.listAllTechProcessesByUnitId = data;
  },

  setSelectedUnitId(state, data) {
    state.selectedUnitId = data;
  },

  setDataEntryMainPage(state, data) {
    state.dataEntryMainPage = data;
  },

  setTransferOrganizationList(state, data) {
    state.transferOrganizationList = data;
  },

  setSentForStorageOrganizationList(state, data) {
    state.sentForStorageOrganizationList = data;
  },
  setDatastored(state, data) {
    state.datastored = data;
  }
};

const getters = {
  GETLISTOFORGANIZATIONSFORSEARCH: (state) =>
    state.listOfOrganizationsForSearch,
  GETLISTALLTECHPROCESSBYUNITID: (state) => state.listAllTechProcessesByUnitId,
  GETSELECTEDUNITID: (state) => state.selectedUnitId,
  GETDATAENTRYMAINPAGE: (state) => state.dataEntryMainPage,
  GETTRANSFERORGANIZATIONLIST: (state) => state.transferOrganizationList,
  GETSENTFORSTORAGEORGANIZATIONLIST: (state) => state.sentForStorageOrganizationList,
  GETDATASTORED: (state) => state.datastored,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
