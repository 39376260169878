import Vue from "vue";
import VueRouter from "vue-router";
import Cookies from "js-cookie";

Vue.use(VueRouter);
let user = {
  role: Cookies.get("role") !== undefined ? Cookies.get("role") : null,
};

let routes = [
  {
    path: "/auth",
    name: "auth",
    hidden: true,
    component: () => import("../views/Login"),
  },
  {
    path: "/registerOfFacilities",
    name: "registerOfFacilities",
    // hidden: true,
    component: () => import("../views/registerOfFacilitiesView"),
  },
];

if (user.role === null) {
  routes.push({
    path: "/",
    name: "Login",
    hidden: true,
    component: () => import("../views/Login"),
  });
}

if (user.role === "ADMIN") {
  routes.push(
    {
      path: "/",
      name: "Login",
      hidden: true,
      component: () => import("../views/viewRouter"),
    },

    {
      path: "/organizations",
      name: "organizations",
      // hidden: true,
      component: () => import("../views/viewRouter"),
      children: [
        {
          path: "main",
          component: () =>
            import("../views/admin/organizations/organizationsMainView"),
        },
      ],
    },

    {
      path: "/users",
      name: "users",
      // hidden: true,
      component: () => import("../views/viewRouter"),
      children: [
        {
          path: "main",
          component: () => import("../views/admin/users/usersMainView"),
        },

        {
          path: "add",
          component: () => import("../views/admin/users/usersAddView"),
        },
      ],
    },

    {
      path: "/classifiers",
      name: "classifiers",
      // hidden: true,
      component: () => import("../views/viewRouter"),
      children: [
        {
          path: "main",
          component: () =>
            import("../views/admin/classifiers/classifiersMainView"),
        },
        {
          path: "edit",
          component: () =>
            import("../views/admin/classifiers/classifiersEditView"),
        },
        {
          path: "tabBelniz",
          component: () => import("../views/admin/classifiers/tabBelnizView"),
        },
      ],
    },
    {
      path: "/subclassifiers",
      name: "subclassifiers",
      // hidden: true,
      component: () => import("../views/viewRouter"),
      children: [
        {
          path: "main",
          component: () =>
            import("../views/admin/subclassifiers/subclassifiersMainView"),
        },

        {
          path: ":subClassifierId",
          component: () =>
            import("../views/admin/subclassifiers/genericSubClassifierView"),
        },

        {
          path: ":areaId/districts",
          component: () =>
            import("../views/admin/subclassifiers/districtsSubClassifierView"),
        },
        {
          path: ":areaId/districts/:districtId",
          component: () =>
            import("../views/admin/subclassifiers/settlementsTypesView"),
        },
        {
          path: ":areaId/districts/:districtId/type/:typeId",
          component: () =>
            import("../views/admin/subclassifiers/citiesSubClassifierView"),
        },
      ],
    },

    {
      path: "/egr",
      name: "egr",
      // hidden: true,
      component: () => import("../views/viewRouter"),
      children: [
        {
          path: "main",
          component: () => import("../views/admin/egr/egrView"),
        },
      ],
    },

    {
      path: "/registries",
      name: "registries",
      // hidden: true,
      component: () => import("../views/viewRouter"),
      children: [
        {
          path: "main",
          component: () =>
            import("../views/admin/registries/registriesMainView"),
        },
        {
          path: "use_registry",
          component: () => import("../views/admin/registries/useRegistry"),
        },
      ],
    }
  );
}

if (user.role !== "ADMIN" && user.role !== null) {
  routes.push(
    {
      path: "/profile",
      name: "profile",
      component: () => import("../views/viewRouter"),
      children: [
        {
          path: "info",
          component: () => import("../views/userProfileView"),
        },
      ],
    },

    {
      path: "/data-agreement",
      name: "dataAgreement",
      component: () => import("../views/viewRouter"),
      children: [
        {
          path: "main",
          component: () =>
            import("../views/ecologist/dataAgreement/dataAgreementView"),
        },
      ],
    },

    {
      path: "/dataEntry",
      name: "DataEntry",
      // hidden: true,
      component: () => import("../views/viewRouter"),
      children: [
        {
          path: "main",
          component: () =>
            import("../views/ecologist/dataEntry/DataEntryMainView"),
        },
        {
          path: "edit/:processId/:wasteId/:unitId",
          component: () =>
            import("../views/ecologist/dataEntry/DataEntryEditView"),
        },
      ],
    },

    {
      path: "/wasteExchange",
      name: "wasteExchange",
      // hidden: true,
      component: () => import("../views/viewRouter"),
      children: [
        {
          path: "main",
          component: () =>
            import("../views/ecologist/wasteExchange/WasteExchangeMainView"),
        },
        {
          path: "add",
          component: () =>
            import("../views/ecologist/wasteExchange/addWasteOfferView"),
        },
        {
          path: "mainconfirm",
          component: () =>
            import("../views/ecologist/wasteExchange/WasteExchangeMainConfirmView"),
        },
        {
          path: "search",
          component: () =>
            import("../views/ecologist/wasteExchange/WasteExchangeSearchView"),
        },
        {
          path: "bed",
          component: () =>
            import("../views/ecologist/wasteExchange/WasteExchangeBedView"),
        },
        {
          path: "bedconfirm",
          component: () =>
            import("../views/ecologist/wasteExchange/WasteExchangeBedConfirmView"),
        },
        {
          path: "maps",
          component: () =>
            import("../views/ecologist/wasteExchange/MapsView"),
        },
        // {
        //   path: "edit/:processId/:wasteId/:unitId",
        //   component: () =>
        //     import("../views/ecologist/dataEntry1/DataEntryEditView1"),
        // },
      ],
    },

    {
      path: "/natResources",
      name: "natResources",
      // hidden: true,
      component: () => import("../views/viewRouter"),
      children: [
        {
          path: "main",
          component: () =>
            import("../views/ecologist/natResources/NatResourcesMainView"),
        },
      ],
    },

    {
      path: "/limits",
      name: "limits",
      // hidden: true,
      component: () => import("../views/viewRouter"),
      children: [
        {
          path: "main",
          component: () =>
            import("../views/ecologist/limits/LimitsMainView"),
        },
      ],
    },

    {
      path: "/firstEncounter",
      name: "firstEncounter",
      // hidden: true,
      component: () => import("../views/viewRouter"),
      children: [
        {
          path: "main",
          component: () =>
            import("../views/ecologist/firstEncounter/firstEncounterMainView"),
        },
      ],
    },

    {
      path: "/branches",
      name: "branches",
      // hidden: true,
      component: () => import("../views/viewRouter"),
      children: [
        {
          path: "main",
          component: () =>
            import("../views/ecologist/branches/BranchesMainView"),
        },

        {
          path: "add",
          component: () =>
            import("../views/ecologist/branches/BranchesAddView"),
        },

        {
          path: "edit/:id",
          component: () =>
            import("../views/ecologist/branches/BranchesEditView"),
        },
      ],
    },

    {
      path: "/subdivisions",
      name: "subdivisions",
      // hidden: true,
      component: () => import("../views/viewRouter"),
      children: [
        {
          path: "main",
          component: () =>
            import("../views/ecologist/subdivisions/subdivisionsMainView"),
        },

        {
          path: "add",
          component: () =>
            import("../views/ecologist/subdivisions/subdivisionsAddView"),
        },

        {
          path: "edit/:id",
          component: () =>
            import("../views/ecologist/subdivisions/subdivisionsEditView"),
        },
      ],
    },

    {
      path: "/districts",
      name: "districts",
      // hidden: true,
      component: () => import("../views/viewRouter"),
      children: [
        {
          path: "main",
          component: () =>
            import("../views/ecologist/districts/districtsMainView"),
        },

        {
          path: "add",
          component: () =>
            import("../views/ecologist/districts/districtsAddView"),
        },

        {
          path: "edit/:id",
          component: () =>
            import("../views/ecologist/districts/districtsEditView"),
        },
      ],
    },

    {
      path: "/wasteproduction",
      name: "wasteproduction",
      // hidden: true,
      component: () => import("../views/viewRouter"),
      children: [
        {
          path: "main",
          component: () =>
            import(
              "../views/ecologist/wasteproduction/wasteproductionMainView"
            ),
        },

        {
          path: "add",
          component: () =>
            import("../views/ecologist/wasteproduction/wasteproductionAddView"),
        },

        {
          path: "edit/:organizationWasteId",
          component: () =>
            import(
              "../views/ecologist/wasteproduction/wasteproductionEditView"
            ),
        },
      ],
    },

    {
      path: "/techprocesses",
      name: "techprocesses",
      // hidden: true,
      component: () => import("../views/viewRouter"),
      children: [
        {
          path: "main",
          component: () =>
            import("../views/ecologist/techprocesses/techprocessesMainView"),
        },

        {
          path: "add",
          component: () =>
            import("../views/ecologist/techprocesses/techprocessesAddView"),
        },

        {
          path: "edit/:id",
          component: () =>
            import("../views/ecologist/techprocesses/techprocessesEditView"),
        },

        {
          path: "addWaste/:id",
          component: () =>
            import(
              "../views/ecologist/techprocesses/techprocessesAddWasteView"
            ),
        },
      ],
    },

    {
      path: "/classifiers",
      name: "classifiers",
      // hidden: true,
      component: () => import("../views/viewRouter"),
      children: [
        {
          path: "main",
          component: () =>
            import("../views/ecologist/classifiers/classifiersMainView"),
        },

        {
          path: "contractors",
          component: () =>
            import(
              "../views/ecologist/classifiers/contractors/contractorsMainView"
            ),
        },

        {
          path: "contractors/add",
          component: () =>
            import(
              "../views/ecologist/classifiers/contractors/contractorsAddView"
            ),
        },

        {
          path: "contractors/edit/:id",
          component: () =>
            import(
              "../views/ecologist/classifiers/contractors/contractorsEditView"
            ),
        },

        {
          path: "administrative_document",
          component: () =>
            import(
              "../views/ecologist/classifiers/administrativeDocument/administrativeDocumentMainView"
            ),
        },

        {
          path: "administrative_document/add",
          component: () =>
            import(
              "../views/ecologist/classifiers/administrativeDocument/administrativeDocumentAddView"
            ),
        },

        {
          path: "administrative_document/edit/:id",
          component: () =>
            import(
              "../views/ecologist/classifiers/administrativeDocument/administrativeDocumentEditView"
            ),
        },

        {
          path: "responsible_persons",
          component: () =>
            import(
              "../views/ecologist/classifiers/responsiblePersons/responsiblePersonsMainView"
            ),
        },

        {
          path: "responsible_persons/add",
          component: () =>
            import(
              "../views/ecologist/classifiers/responsiblePersons/responsiblePersonsAddView"
            ),
        },

        {
          path: "responsible_persons/edit/:id",
          component: () =>
            import(
              "../views/ecologist/classifiers/responsiblePersons/responsiblePersonsEditView"
            ),
        },
      ],
    },

    {
      path: "/reports",
      name: "reports",
      // hidden: true,
      component: () => import("../views/viewRouter"),
      children: [
        {
          path: "main",
          component: () => import("../views/ecologist/reports/reportsMainView"),
        },

        {
          path: "pod-9",
          component: () => import("../views/ecologist/reports/POD-9/POD-9View"),
        },
        {
          path: "pod-9-2023",
          component: () =>
            import("../views/ecologist/reports/POD-9/POD-9-2023View"),
        },
        {
          path: "waste-1",
          component: () =>
            import("../views/ecologist/reports/Waste-1/Waste-1View"),
        },
        {
          path: "epp",
          component: () =>
            import("../views/ecologist/reports/epp/eppView"),
        },
        {
          path: "pod-10",
          component: () =>
            import("../views/ecologist/reports/POD-10/POD-10View"),
        },
        {
          path: "pod-10-2023",
          component: () =>
            import("../views/ecologist/reports/POD-10/POD-10-2023View"),
        },
        {
          path: "passport",
          component: () =>
            import("../views/ecologist/reports/passport/PassportView"),
        },
        {
          path: "logpassport",
          component: () =>
            import("../views/ecologist/reports/passport/LogPassportView"),
        },
        {
          path: "MainReport",
          component: () =>
            import("../views/ecologist/reports/MainReport/MainReportView"),
        },
        {
          path: "main-report-table",
          component: () =>
            import("../views/ecologist/reports/MainReport/MainReportTableView"),
        },
      ],
    },

    {
      path: "/disposal",
      name: "disposal",
      // hidden: true,
      component: () => import("../views/viewRouter"),
      children: [
        {
          path: "main",
          component: () =>
            import("../views/ecologist/disposal/disposalMainView"),
        },

        {
          path: "accompanying-passportView",
          component: () =>
            import("../views/ecologist/disposal/accompanyingPassportView"),
        },

        {
          path: "selected-waste",
          component: () =>
            import("../views/ecologist/disposal/disposalSelectedWasteListView"),
        },
        {
          path: "accompanying-passport",
          component: () =>
            import("../components/ecologist/disposal/accompanyingPassport"),
        },

        {
          path: "pod-9",
          component: () =>
            import("../views/ecologist/disposal/disposalMainView"),
        },
      ],
    },
    {
      path: "/rof",
      name: "rof",
      // hidden: true,
      component: () => import("../views/viewRouter"),
      children: [
        {
          path: "table",
          component: () =>
            import("../views/ecologist/registerOfFacilities/tableView"),
        },
      ],
    }
  );
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
